import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';
import { BrowserTracing } from '@sentry/tracing';

const SentryDSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const APP_ENV = process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV;

if (APP_ENV !== 'development') {
  Sentry.init({
    dsn: SentryDSN,
    tracesSampleRate: 1.0,
    integrations: [new BrowserTracing()],
    environment: APP_ENV,
    enabled: APP_ENV !== 'development',
  });

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

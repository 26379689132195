import Head from 'next/head';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ReactElement, ReactNode, useEffect } from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import ToastContainer from '../components/toast-container/toast-container.component';
import ProgressBar from '../components/progress-bar/progress-bar.component';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

import GlobalStyle from '../styles/global.styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity, cacheTime: Infinity },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);

  useEffect(() => {
    if (
      window.location.protocol === 'http:' &&
      process.env.NODE_ENV === 'production'
    ) {
      window.location.href = window.location.href.replace('http', 'https');
    }

    if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID as string);

      setupLogRocketReact(LogRocket);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>LivestockLog</title>
      </Head>

      <GlobalStyle />

      <ToastContainer />

      <ProgressBar />

      <QueryClientProvider client={queryClient}>
        {getLayout(<Component {...pageProps} />)}

        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;

export const colors = {
  primary: '#008080',
  white: '#fff',
  black: '#000',
  white1: '#edf2f7',
  white2: '#e2e8f0',
  white3: '#f7fafc',
  black1: '#1A202C',
  gray: '#2c7a7b',
  gray1: '#319795',
  gray2: '#adadad',
  gray3: '#808080',
  gray4: '#cbd5e0',
  blue: '#3182ce',
  error: '#da1457',
};

import { createGlobalStyle } from 'styled-components';

import { colors } from '../lib/colors';

const GlobalStyle = createGlobalStyle`
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
    background-color: ${colors.primary};
    scroll-behavior: smooth;

    @media only screen and (max-width: 20em) {
        font-size: 50%;
    }
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${colors.black};
    background-color: #f6f6f6;
    max-width: 140rem;
    margin: 0 auto;   
}

a {
    text-decoration: none;
    color: currentColor;
    outline: transparent dashed 1.5px;
    outline-offset: 2px;
    transition: outline-color 0.2s;

    &:focus-visible {
      outline-color: ${colors.black};
    }
}

li {
    list-style: none;
}
`;

export default GlobalStyle;
